import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["status", "transporter", "vehicles", "delayedmessage", "changedmessage", "select"]

  initialize() {
    this.url = this.data.get('url');
    this.interest = this.data.get('interest');
    this.job_id = this.data.get('job-id');
    this.transporter = this.data.get('transporter');
    this.old_transporter_id = this.data.get('old-transporter-id');
    this.transporter_offer = document.getElementById("job-transporter-offer");
    this.tr_invoice_amount = document.getElementById("invoice_amount");
    this.update_state(this.interest, this.job_id, this.transporterTarget.value, this.data.get("vehicle"));
    if (this.old_transporter_id && this.old_transporter_id != 0 && this.transporter && this.old_transporter_id != this.transporter) {
      alert("need send message to " + this.old_transporter_id);
    }
  }
  
  set_vehicles(event) {
    event.preventDefault;
    event.stopPropagation;
    this.update_state(this.interest, this.job_id, event.currentTarget.value);
  }

  update_state(id, job_id, transporter, vehicle=null) {
    if (this.statusTarget.value == 'delayed') {
      this.delayedmessageTarget.classList.remove("hidden");
      $(this.delayedmessageTarget).find("input").attr("required", true);
    }
    if (this.old_transporter_id && this.old_transporter_id != "0" && transporter && this.old_transporter_id != transporter) {
      this.changedmessageTarget.classList.remove("hidden");
      $(this.changedmessageTarget).find("input").attr("required", true);
    } else {
      if (!this.changedmessageTarget.classList.contains("hidden")) {
        this.changedmessageTarget.classList.add("hidden");
        $(this.changedmessageTarget).find("input").attr("required", false);
      }
    }
    this.toggle_carrier_invoice(transporter);
    if(transporter){
      $.ajax({
        url: this.url,
        type: "GET",
        datatype: "json",
        data: {
          "id": job_id,
          "transporter_id": transporter
        }
      }).done(function (data) {
        if(data && data["vehicles"]){
          var vehicle_select = document.getElementById(id);
          vehicle_select.innerHTML = "";
          var vehicles = []
          vehicles.push(["All Vehicles", 'all']);
          for(let i = 0; i < data["vehicles"].length; i++){
            vehicles.push(data["vehicles"][i]);
          }
          if(vehicle === null || vehicle === ""){
            vehicle = "all";
          }
          vehicle_select.innerHTML = vehicles
            .map(element => `<option value="${element[1]}">${element[0]}</options>`)
            .join("");
          vehicle_select.value = vehicle;
          var new_vehicle = document.getElementById("add-new-vehicle");
          if (new_vehicle) {
            if(vehicle == 'all'){
              $(new_vehicle).removeClass("hidden").addClass("sm:grid");
            } else if (vehicle != 'all') {
              $(new_vehicle).addClass("hidden").removeClass("sm:grid");
            }
          }
          if (transporter == 'none') {
            var transporter_offer = document.getElementById("job-transporter-offer");
            var tr_invoice_amount = document.getElementById("invoice_amount");
            $(transporter_offer).val('');
            $(tr_invoice_amount).val('');
          }
        }
      });
    }
  }

  set_status(event){
    event.preventDefault;
    var transporter_offer = document.getElementById("transporter_price");
    var tr_invoice_amount = document.getElementById("invoice_amount");
    if (event.currentTarget.value == 'all'){
      if(this.statusTarget){ 
        this.statusTarget.value = 'pending';
      }
      if (tr_invoice_amount){
        tr_invoice_amount.value = '';
      }
    } else if (event.currentTarget.value != 'all'){
      if (this.statusTarget) { 
        this.statusTarget.value = "pending_client";
      }
    }
  }

  set_unset_add_vehicle(event){
    var new_vehicle = document.getElementById("add-new-vehicle");
    if(new_vehicle){
      if (event.currentTarget.value == 'all') {
        $(new_vehicle).removeClass("hidden").addClass("sm:grid");
      } else if (event.currentTarget.value != 'all'){
        $(new_vehicle).addClass("hidden").removeClass("sm:grid");
      }
    }
  }

  toggle_delayed(event){
    var delayed = document.getElementById("delayed-message");
    if(delayed){
      if (event.currentTarget.value == 'delayed') {
        $(delayed).removeClass("hidden");
        $(delayed).find("input").attr("required", true);
      } else {
        $(delayed).addClass("hidden");
        $(delayed).find("input").removeAttr("required");
      }
    }
  }

  toggle_carrier_invoice(transporter){
    var transporter_invoice_docs = document.getElementById("transporter_invoice_docs");
    var transporter_nil_message = document.getElementById("transporter_nil_message");
    var transporter_id = parseInt(transporter);
    if (isNaN(transporter_id) || transporter_id == 0){
      if (transporter_invoice_docs) {
        transporter_invoice_docs.classList.add("hidden");
      }
      if (transporter_nil_message) {
        transporter_nil_message.classList.remove("hidden");
      }
    } else {
      if (transporter_invoice_docs) {
        transporter_invoice_docs.classList.remove("hidden");
      }
      if (transporter_nil_message) {
        transporter_nil_message.classList.add("hidden");
      }
    }
  }
}
