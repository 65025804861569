import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["closebutton", "password", "passwordConfirmation", "message", "messageLabel"]

  toggle(ev) {
    $(this.closebuttonTarget).toggleClass("hidden");
    ev.preventDefault();
    return false;
  }

  check_required(event) {
    var obj = $(event.currentTarget).closest("form");
    var retur = false;
    obj.find("input[type!='hidden'], select, textarea").each(function () {
      if ($(this).attr("required") && $(this).attr("required") == "required"){
        if ($(this).val() === "" || $(this).val() === null) {
          retur = false;
          this.classList.remove("border-grey-300");
          this.classList.add("border-red-400");
        } else {
          retur = true;
          this.classList.remove("border-red-400");
          this.classList.add("border-grey-300");
        }
      }   
    });
    return retur;
  }

  check_required_object(event) {
    var current_object = event.currentTarget;
    if ($(current_object).attr("required") && $(current_object).attr("required") == 'required'){
      if ($(current_object).val() === "" || $(current_object).val() === null) {
        current_object.classList.remove("border-grey-300");
        current_object.classList.add("border-red-400");
      } else {
        current_object.classList.remove("border-red-400");
        current_object.classList.add("border-grey-300");
      }
    }   
  }

  check_password_confirmation(event) {
    var pass = this.passwordTarget.value;
    var pass_conf = this.passwordConfirmationTarget.value;
    if (pass_conf != null) {
      if (pass === null) {
        pass = "";
      }
    }
    var message = this.messageTarget;
    var message_label = this.messageLabelTarget;
    if (pass_conf != null && pass_conf != "") {
      if (pass_conf != pass) {
        event.currentTarget.classList.remove("border-grey-300");
        event.currentTarget.classList.add("border-red-600");
        message_label.classList.remove("hidden");
        message_label.classList.add("block");
        message.classList.remove("hidden");
        message.classList.add("block");
      } else {
        event.currentTarget.classList.remove("border-red-600");
        event.currentTarget.classList.add("border-grey-300");
        message_label.classList.add("hidden");
        message_label.classList.remove("block");
        message.classList.add("hidden");
        message.classList.remove("block");
      }
    } else {
      if (pass === null || pass == "") {
        event.currentTarget.classList.remove("border-red-600");
        event.currentTarget.classList.add("border-grey-300");
        message_label.classList.add("hidden");
        message_label.classList.remove("block");
        message.classList.add("hidden");
        message.classList.remove("block");
      }
    } 
  }
}