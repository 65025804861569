import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["notifications", "userspop"]

  clickAvatar(ev) {
    $(this.userspopTarget).toggleClass("hidden")
    return false
  }
  
  clickNotification(ev) {
    $(this.notificationsTarget).toggleClass("hidden")
    return false
  }
}
