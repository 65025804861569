// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";global.Rails = Rails;
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "chartkick/chart.js"

require("jquery")

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import "controllers";
import "../stylesheets/application";

require("packs/select2setup");

// window.initMap = function(...args){
//   const event = document.createEvent("Events");
//   event.initEvent("google-maps-callback", true, true);
//   event.args = args;
//   window.dispatchEvent(event);
// }
