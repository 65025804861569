import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["accepted", "reject", "form", "selectedAction"];

  conect() {
    // console.log("accepted");
  }

  accept(e){
    e.preventDefault();
    this.selectedActionTarget.value = "accept";
    Rails.fire(this.formTarget, 'submit');
  }

  reject(e){
    e.preventDefault();
    this.selectedActionTarget.value = "reject";
    Rails.fire(this.formTarget, 'submit');
  }

}