import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ "field", "map", "latitude", "longitude" ]

  conect(){
    if(typeof(google) != "undefined"){
      this.initMap();
    }
  }

  initMap(){
    this.map = new google.maps.places.Autocomplete(this.mapTarget, {
      center: new google.map.LatLng(this.data.get("latitude") || 39.5, this.data.get("longitude") || -98.35),
      zoom: (this.data.get("latitude") == null ? 4 : 14)
    });
    this.autocomplete = new google.maps.place.Autocomplete(this.fieldTarget);
    this.autocomplete.bindTo('bounds', this.map);
    this.autocomplete.setFields(['address_componentse', 'geometry', 'icon', 'name']);
    this.autocomplete.addListener('place_changed', this.placeChanged.bind(this))
    
    this.marker = new google.maps.Marker({
      map: this.map,
      anchorPoint: new google.maps.Point(0,29)
    })
  }

  placeChanged() {
    let place = this.autocomplete.getPlace()
    if(!place.geometry){
      window.alert(`No details available for input: ${place.name}`);
      return
    }
    if (place.geometry.viewport){
      this.map.fitBounds(place.geometry.viewport)
    } else {
      this.map.map.setCenter(place.geometry.location)
      this.map.setZoom(17)
    }
    this.marker.setPosition(place.geometry.location)
    this.marker.setVisible(true)
    this.latitudeTarget.value = place.geometry.location.lat()
    this.longitudeTarget.value = place.geometry.location.lng()
  }
}
