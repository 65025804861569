import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["source"]

  initialize() {
    this.url = this.data.get('url');
    this.interest = this.data.get('interest');
    this.user_id = this.data.get('user-id');
    this.role = this.data.get('role');
    this.owner_id = this.data.get('owner-id');
    this.owner_label = this.data.get('owner-label');
    this.current_user = this.data.get('current-user');
    this.update_state(this.interest, this.owner_label, this.user_id, this.role, this.role, this.owner_id, this.sourceTarget.value, this.current_user);
  }

  set_owners(event) {
    event.preventDefault;
    event.stopPropagation;
    this.update_state(this.interest, this.owner_label, this.user_id, this.role, event.currentTarget.value, this.owner_id, this.sourceTarget.value, this.current_user);
  }

  update_state(id, label, user_id, init_role, role, owner, source, current_user) {
    if (role) {
      $.ajax({
        url: this.url,
        type: "GET",
        datatype: "json",
        data: {
          "id": user_id,
          "source": role,
          "current_user_id": current_user
        }
      }).done(function (data) {
        if (data && data["owners"]) {
          var owner_select = document.getElementById(id);
          var label_owner = document.getElementsByClassName(label); 
          var user_owner = document.getElementById("user_owner");
          if(owner_select){
            owner_select.innerHTML = "";
          }
          var owners = []
          // owners.push(["All Vehicles", 'all']);
          for (let i = 0; i < data["owners"].length; i++) {
            owners.push(data["owners"][i]);
          }
          if (owner === null || owner === "") {
            owner = "all";
          }
          if (init_role && init_role != role){
            owner = "all";
          }
          // console.log(role);
          if(role == 'admin' || role == 'admin-client' || role == "admin-transporter" || role == "admin-invoices"){
            $(user_owner).addClass("hidden").removeClass("sm:grid");
          }else{
            $(label_owner).text(role.charAt(0).toUpperCase() + role.slice(1));
            if (owner_select) {
              if (owners) {
                owner_select.innerHTML = owners
                  .map(element => `<option value="${element[1]}">${element[0]}</options>`)
                  .join("");
                owner_select.value = owner;
              }
            }
            $(user_owner).removeClass("hidden").addClass("sm:grid");
          }
        }
      });
    }
  }

  // set_status(event) {
  //   event.preventDefault;
  //   if (event.currentTarget.value == 'all') {
  //     this.statusTarget.value = 'pending';
  //   } else if (event.currentTarget.value != 'all') {
  //     this.statusTarget.value = "pending_client";
  //   }
  // }
}
