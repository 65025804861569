import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["country", "states"]

  initialize() {
    this.url = this.data.get('url');
    this.interest = this.data.get('interest');
    // this.statesTarget.value = this.data.get("state");
    this.index = this.data.get('index');
    var country = document.getElementById(`${this.interest.replace('state', 'country')}`);
    if (country){
      this.update_state(this.interest, this.countryTarget.value, this.data.get("state"));
    }else{
      if (this.index == '0') {
        if (this.interest) {
          var destroy = document.getElementById(`${this.interest.replace('state', '_destroy')}`);
          if (destroy) {
            $(destroy).closest(".address_destroy").addClass("hidden").removeClass("sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-2 sm:pb-2");
          }
        }
      }
    }
    // this.update_state(this.interest, "RO", "BH");
  }
  
  set_states(event) {
    event.preventDefault;
    if(event.detail){
      this.update_state(this.interest, event.currentTarget.value, event.detail.state);
    } else {
      this.update_state(this.interest, event.currentTarget.value);
    }
  }

  delete_address(event) {
    event.preventDefault;
    var object = event.currentTarget;
    var object_to_remove = $(object).closest(".address_new_record");
    if (object_to_remove.length > 0){
      object_to_remove.remove();
    }
    var interest = $(object).attr("data-interest");
    if (interest){
      var object_to_remove = $(object).closest(`.${interest}`);
      if (object_to_remove.length > 0) {
        if ($(object_to_remove).attr("data-addressfields-index") != "0") {
          object_to_remove.remove();
        } else {
          object.checked = false;
        }
      }
    }
  }

  update_state(id, country, state=null) {
    if(this.index == '0'){
      if(this.interest){
        var destroy = document.getElementById(`${this.interest.replace('state', '_destroy')}`);
        if(destroy){
          $(destroy).closest(".address_destroy").addClass("hidden").removeClass("sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-2 sm:pb-2");
        } 
      }
    }
    if(this.countryTarget){
      var state_select = document.getElementById(id);
      if(state_select){
        if(country){
          $.ajax({
            url: this.url,
            type: "GET",
            datatype: "json",
            data: {
              "country": country,
            }
          }).done(function (data) {
            if(data && data["states"]){
              var state_select = document.getElementById(id);
              if(state_select){
                state_select.innerHTML = "";
                var states = []
                states.push(["Please select State/Province", '']);
                for(let i = 0; i < data["states"].length; i++){
                  states.push(data["states"][i]);
                }
                if(state === null){
                  state = "";
                }
                state_select.innerHTML = states
                  .map(element => `<option value="${element[1]}">${element[0]}</options>`)
                  .join("");
                state_select.value = state;
              }
            }
          });
        }
      }
    }
  }
}