// This is a replacement for the built-in Rails ujs confirmation method when using Bootstrap.
// Trying to hook into the built-in stuff is a pain, and changes with every Rails (now Hotwire)
// version.
import { Modal } from "tailwindcss-stimulus-components/dist/tailwindcss-stimulus-components";

export default class extends Modal {
  static values = {
    title: String,
    message: String,
    okButton: String,
    cancelButton: String,
    url: String,
    method: String,
    frame: String,
    remote: String
  }
  
  static targets = ['container', 'background'];

  connect() {
    super.connect();
  }

  // open(e) {
  //   if (this.preventDefaultActionOpening) {
  //     e.preventDefault();
  //   }

  //   e.target.blur();

  //   // Lock the scroll and save current scroll position
  //   this.lockScroll();

  //   // Unhide the modal
  //   document.addEventListener("postForm:load", () => {
  //     this.containerTarget.classList.remove(this.toggleClass);
  //   });

  //   // Insert the background
  //   if (!this.data.get("disable-backdrop")) {
  //     document.body.insertAdjacentHTML('beforeend', this.backgroundHtml);
  //     this.background = document.querySelector(`#${this.backgroundId}`);
  //   }
  // }

  close(event) {
    if(event){
      event.preventDefault();
      let button = event.currentTarget;
      button.closest("div[id='temp-html']").remove();
    }
  }

  closeBackground(e) {
    if(e){
      let button = e.currentTarget;
      this.close(e);
    }
  }

  closeWithKeyboard(e) {
    if(e){
      if (e.keyCode === 27) {
        this.close(e)
      }
    }
  }

  view(event) {
    event.preventDefault();
    event.stopPropagation();
    let button = event.currentTarget;
    let title = button.dataset["title"];
    let msg = button.dataset["message"];
    let ok = button.dataset["okButton"];
    if (!ok) { ok = 'OK'; }
    let cancel = button.dataset["cancelButton"];
    if (!cancel) { cancel = 'Cancel'; }
    let url = button.dataset["urlDocument"];
    let method = button.dataset["method"];
    let remote = button.dataset["remote"];
    if (remote && remote=="true"){
      remote = `data-remote="${remote}"`;
    } else {
      remote = "";
    }
    if (!method) { method = 'get'; }
    let frame = button.dataset["frame"];
    if (frame) {
      frame = `data-turbo-frame="${frame}"`;
    }

    let dMethod = method === 'get' ? '' : " data-method=\"" + method + "\"";
    
    let template = `
<!-- This example requires Tailwind CSS v2.0+ -->
<div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="modal-wrapper flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0" data-confirm-target='background' data-action='click->confirm#closeBackground'>
    <!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

    <!-- This element is to trick the browser into centering the modal contents. -->
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

    <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    -->
    <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
      <div data-confirm-target='content'>
        <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
          <button type="button" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" data-action="click->confirm#close keyup@window->confrim#closeWithKeyboard">
            <span class="sr-only">Close</span>
            <!-- Heroicon name: outline/x -->
            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div class="sm:flex sm:items-start">
          <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <!-- Heroicon name: outline/exclamation -->
            <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
            </svg>
          </div>
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
              ${title}
            </h3>
            <div class="mt-2">
              <p class="text-sm text-gray-500">
                ${msg}
              </p>
            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <a href="${url}" ${dMethod} ${remote} class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            ${frame} data-action="click->confirm#close keyup@window->confrim#closeWithKeyboard">
            ${ok}
          </a>
          <button type="button" data-action="click->confirm#close keyup@window->confrim#closeWithKeyboard" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
    `;

    var tempDiv = document.createElement('div');
    tempDiv.id = 'temp-html';
    tempDiv.setAttribute("data-controller","confirm");
    tempDiv.innerHTML = template;

    document.body.append(tempDiv);    
  }

  template() {
    return `
      <div data-remote-target='container' class='fixed z-10 inset-0 overflow-y-auto'>
        <div class='modal-wrapper flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block md:block sm:p-0' data-remote-target='background' data-action='click->confirm#closeBackground'>
          <!-- This element is to trick the browser into centering the modal contents. -->
          <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          <div class='inline-block align-bottom bg-white border border-gray-300 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all md:w-4/12 md:p-6 md:my-4 sm:my-2 sm:align-middle sm:min-w-max sm:p-2'>
            <div data-remote-target='content'>

            </div>
          </div>
        </div>
      </div>
    `
  }
}