import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mobilemenu", "closebutton", "dropdown"]

  toggle(ev) {
    var width = window.innerWidth;
    if (width < 1024) {
      this.mobilemenuTarget.classList.toggle("hidden");
    }
  }

  closebut(event) {
    this.mobilemenuTarget.classList.toggle("hidden");
  }
}