import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["invoice_client_id", "invoice_notificiation_setting"]

  initialize() {
    this.url = this.data.get('url');
    this.client_id = this.data.get('client-id');
    this.action = this.data.get('type');
    this.update_users(this.client_id, this.action, this.client_id);
  }

  set_users(event) {
    event.preventDefault;
    event.stopPropagation;
    this.update_users(event.currentTarget.value, this.action, this.client_id);
  }

  update_users(id, action, old_id) {
    if (id) {
      if(action == 'new' || id != old_id){
        $.ajax({
          url: this.url,
          type: "GET",
          datatype: "json",
          data: {
            "client_id": id
          }
        }).done(function (data) {
          if(data && data["users"]){
            var users_select = document.getElementById("invoice_notification_setting");
            if (users_select){ 
              users_select.innerHTML = "";
              var users = [];
              users.push(["All Users", 'all']);
              users.push(["No Notification", "no"]);
              for (let i = 0; i < data["users"].length; i++) {
                users.push(data["users"][i]);
              }
              users_select.innerHTML = users
                .map(element => `<option value="${element[1]}">${element[0]}</options>`)
                .join("");
              users_select.value = "no";
            }
          }
        });
      }
    }else{
      var users_select = document.getElementById("invoice_notification_setting");
      if (users_select) {
        users_select.innerHTML = "";
        var users = [];
        users.push(["All Users", 'all']);
        users.push(["No Notification", "no"]);
        users_select.innerHTML = users
          .map(element => `<option value="${element[1]}">${element[0]}</options>`)
          .join("");
        users_select.value = "no";
      }
    }
  }
}
