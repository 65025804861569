import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["form_job_search"]
  
  connect() {
    // this.form = this.form_job_searchTarget;
    // console.log("Hello, Stimulus!", this.element);
  }

  set_order(event) {
    var obj = event.currentTarget;
    var form = document.getElementById("form_job_search");
    var order_by = obj.dataset["jobsColumn"];
    var direction = obj.dataset["jobsDirection"];
    var query = {};
    query["order_by"] = order_by;
    query["direction"] = direction;
    
    if (form) {
      var elements = form.elements;
      var i;
      query["condition"] = {};
      for (i = 0; i < elements.length; i++) {
        var item = elements[i];
        if (item.tagName == "INPUT" || item.tagName == "SELECT") {
          if (item.id != '') {
            var opt = item.id.replace("select_", '').replace("condition_", "");
            if (query["condition"][opt] && (query["condition"][opt] == "" || query["condition"][opt] == "all") && item.value != "") {
              query["condition"][opt] = item.value;
            } else {
              query["condition"][opt] = item.value;
            }
          }
        }
      }
    }
    $.ajax({
      url: "jobs",
      type: "GET",
      dataType: "script",
      data: query
    })
  }
}

// *** before ***
// import ApplicationController from './application_controller'

// /* This is the custom StimulusReflex controller for the Job Reflex.
//  * Learn more at: https://docs.stimulusreflex.com
//  */
// export default class extends ApplicationController {
//   /*
//    * Regular Stimulus lifecycle methods
//    * Learn more at: https://stimulusjs.org/reference/lifecycle-callbacks
//    *
//    * If you intend to use this controller as a regular stimulus controller as well,
//    * make sure any Stimulus lifecycle methods overridden in ApplicationController call super.
//    *
//    * Important:
//    * By default, StimulusReflex overrides the -connect- method so make sure you
//    * call super if you intend to do anything else when this controller connects.
//   */

//   connect () {
//     super.connect()
//     // add your code here, if applicable
//   }

//   /* Reflex specific lifecycle methods.
//    *
//    * For every method defined in your Reflex class, a matching set of lifecycle methods become available
//    * in this javascript controller. These are optional, so feel free to delete these stubs if you don't
//    * need them.
//    *
//    * Important:
//    * Make sure to add data-controller="job" to your markup alongside
//    * data-reflex="Job#dance" for the lifecycle methods to fire properly.
//    *
//    * Example:
//    *
//    *   <a href="#" data-reflex="click->Job#dance" data-controller="job">Dance!</a>
//    *
//    * Arguments:
//    *
//    *   element - the element that triggered the reflex
//    *             may be different than the Stimulus controller's this.element
//    *
//    *   reflex - the name of the reflex e.g. "Job#dance"
//    *
//    *   error/noop - the error message (for reflexError), otherwise null
//    *
//    *   reflexId - a UUID4 or developer-provided unique identifier for each Reflex
//    */

//   // Assuming you create a "Job#dance" action in your Reflex class
//   // you'll be able to use the following lifecycle methods:

//   // beforeDance(element, reflex, noop, reflexId) {
//   //  element.innerText = 'Putting dance shoes on...'
//   // }

//   // danceSuccess(element, reflex, noop, reflexId) {
//   //   element.innerText = '\nDanced like no one was watching! Was someone watching?'
//   // }

//   // danceError(element, reflex, error, reflexId) {
//   //   console.error('danceError', error);
//   //   element.innerText = "\nCouldn\'t dance!"
//   // }

//   // afterDance(element, reflex, noop, reflexId) {
//   //   element.innerText = '\nWhatever that was, it\'s over now.'
//   // }

//   // finalizeDance(element, reflex, noop, reflexId) {
//   //   element.innerText = '\nNow, the cleanup can begin!'
//   // }
// }
