import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["quote_client_id", "quote_notificiation_setting", "pickup_predefined_addresses", "dropoff_predefined_addresses"]

  initialize() {
    this.url = this.data.get('url');
    this.address_url = this.data.get("address_url");
    this.client_id = this.data.get('client-id');
    this.action = this.data.get('type');
    this.stand_alone = this.data.get('stand-alone');
    this.update_addresses(this.client_id, this.action, this.client_id);
  }

  set_addresses(event) {
    event.preventDefault;
    event.stopPropagation;
    this.update_addresses(event.currentTarget.value, this.action, this.client_id);
  }

  update_addresses(id, action, old_id) {
    if (id) {
      if(action == 'new' || id != old_id){
        $.ajax({
          url: this.url,
          type: "GET",
          datatype: "json",
          data: {
            "client_id": id
          }
        }).done(function (data) {
          if (data && data["addresses"]) {
            var pickup_select = document.getElementById("predefined_address_pickup_id");
            var dropoff_select = document.getElementById("predefined_address_dropoff_id");
            pickup_select.innerHTML = "";
            dropoff_select.innerHTML = "";
            var pickups = [];
            var dropoffs = [];
            if (data["addresses"]["pickups"]){
              pickups.push(["Select Pre-Defined Address", '']);
              for (let i = 0; i < data["addresses"]["pickups"].length; i++) {
                pickups.push(data["addresses"]["pickups"][i]);
              }
              pickup_select.innerHTML = pickups
                .map(element => `<option value="${element[1]}">${element[0]}</options>`)
                .join("");
              pickup_select.value = "";
            }
            if (data["addresses"]["dropoffs"]) {
              dropoffs.push(["Select Pre-Defined Address", '']);
              for (let i = 0; i < data["addresses"]["dropoffs"].length; i++) {
                dropoffs.push(data["addresses"]["dropoffs"][i]);
              }
              dropoff_select.innerHTML = dropoffs
                .map(element => `<option value="${element[1]}">${element[0]}</options>`)
                .join("");
              dropoff_select.value = "";
            }
            for (const interest of ["pickup", "dropoff"]) {
              var company_name = document.getElementById(`quote_${interest}_addresses_attributes_0_company_name`);
              var country = document.getElementById(`quote_${interest}_addresses_attributes_0_country`);
              var address_line1 = document.getElementById(`quote_${interest}_addresses_attributes_0_address_line1`);
              var city = document.getElementById(`quote_${interest}_addresses_attributes_0_city`);
              var zip_code = document.getElementById(`quote_${interest}_addresses_attributes_0_zip_code`);
              var interest_class = document.getElementsByClassName(interest);
              $(interest_class[0]).attr("data-addressfields-country", "");
              $(interest_class[0]).attr("data-addressfields-state", "");
              if (company_name){
                company_name = '';
              }
              if (country) {
                country.value = '';
              }
              var event = new CustomEvent("change", {
                detail: {
                  state: ''
                }
              });
              if (country) {
                country.dispatchEvent(event);
              }
              if (address_line1) {
                address_line1.value = '';
              }
              if (city) {
                city.value = '';
              }
              if (zip_code) {
                zip_code.value = '';
              }
            }
            $(".pickup[data-addressfields-index!='0']").remove();
            $(".dropoff[data-addressfields-index!='0']").remove();
          }
          if(data && data["users"]){
            var users_select = document.getElementById("quote_notification_setting");
            if (users_select){ 
              users_select.innerHTML = "";
              var users = [];
              users.push(["All Users", 'all']);
              users.push(["No Notification", "no"]);
              for (let i = 0; i < data["users"].length; i++) {
                users.push(data["users"][i]);
              }
              users_select.innerHTML = users
                .map(element => `<option value="${element[1]}">${element[0]}</options>`)
                .join("");
              users_select.value = "all";
            }
          }
        });
      }
    }else{
      var pickup_select = document.getElementById("predefined_address_pickup_id");
      var dropoff_select = document.getElementById("predefined_address_dropoff_id");
      var pickups = [];
      var dropoffs = [];
      if (pickup_select){
        pickup_select.innerHTML = "";
      }
      if (dropoff_select){
        dropoff_select.innerHTML = "";
      }
      pickups.push(["Select Pre-Defined Address", '']);
      dropoffs.push(["Select Pre-Defined Address", '']);
      if (pickup_select){
        pickup_select.innerHTML = pickups
          .map(element => `<option value="${element[1]}">${element[0]}</options>`)
          .join("");
        pickup_select.value = "";
      }
      if (dropoff_select){
        dropoff_select.innerHTML = dropoffs
          .map(element => `<option value="${element[1]}">${element[0]}</options>`)
          .join("");
        dropoff_select.value = "";
      }
      if(this.stand_alone == "false"){
        for (const interest of ["pickup", "dropoff"]) {
          var company_name = document.getElementById(`quote_${interest}_addresses_attributes_0_company_name`);
          var country = document.getElementById(`quote_${interest}_addresses_attributes_0_country`);
          var address_line1 = document.getElementById(`quote_${interest}_addresses_attributes_0_address_line1`);
          var city = document.getElementById(`quote_${interest}_addresses_attributes_0_city`);
          var zip_code = document.getElementById(`quote_${interest}_addresses_attributes_0_zip_code`);
          var interest_class = document.getElementsByClassName(interest);
          $(interest_class[0]).attr("data-addressfields-country", "");
          $(interest_class[0]).attr("data-addressfields-state", "");
          if(company_name){
            company_name.value = '';
          }
          if(country){
            country.value = '';
          }
          var event = new CustomEvent("change", {
            detail: {
              state: ''
            }
          });
          if(country){
            country.dispatchEvent(event);
          }
          if(address_line1){
            address_line1.value = '';
          }
          if(city){
            city.value = '';  
          }
          if(zip_code){
            zip_code.value = '';
          }
        }
      }
      var users_select = document.getElementById("quote_notification_setting");
      if (users_select) {
        users_select.innerHTML = "";
        var users = [];
        users.push(["All Users", 'all']);
        users.push(["No Notification", "no"]);
        users_select.innerHTML = users
          .map(element => `<option value="${element[1]}">${element[0]}</options>`)
          .join("");
        users_select.value = "no";
      }
    }
  }

  set_pickup(event){
    var selected = event.currentTarget.value;
    if (selected && selected != "") {
      var series = $(event.currentTarget).closest(".pickup").attr("data-addressfields-index");
      this.update_address(selected, "pickup", series);
    }
  }

  set_dropoff(event){
    var selected = event.currentTarget.value;
    if (selected && selected != "") {
      var series = $(event.currentTarget).closest(".dropoff").attr("data-addressfields-index");
      this.update_address(selected, "dropoff", series);
    }
  }

  update_address(id, interest, series){
    if(series === null){
      series = '0';
    }
    if (id){
      $.ajax({
        url: this.address_url,
        type: "GET",
        datatype: "json",
        data: {
          "address_id": id
        }
      }).done(function (data) {
        if (data["address"]){
          var company_name = document.getElementById(`quote_${interest}_addresses_attributes_${series}_company_name`);
          var country = document.getElementById(`quote_${interest}_addresses_attributes_${series}_country`);
          var address_line1 = document.getElementById(`quote_${interest}_addresses_attributes_${series}_address_line1`);
          var city = document.getElementById(`quote_${interest}_addresses_attributes_${series}_city`);
          var zip_code = document.getElementById(`quote_${interest}_addresses_attributes_${series}_zip_code`);
          var interest_class = document.getElementsByClassName(interest);
          $(interest_class[0]).attr("data-addressfields-country", data["address"]["country"]);
          $(interest_class[0]).attr("data-addressfields-state", data["address"]["state"]);
          company_name.value = data["address"]["company_name"];
          country.value = data["address"]["country"];
          var event = new CustomEvent("change", {
            detail: {
              state: data["address"]["state"]
            }
          });
          // country.dispatchEvent(event);
          address_line1.value = data["address"]["address_line1"];
          city.value = data["address"]["city"];
          zip_code.value = data["address"]["zip_code"];
        }
      })
    }
  }
}
